import cogoToast from 'cogo-toast';

const toastHandler = (type, message, header, errorCode) => {
  const toastOptions = {
    error: {
      hideAfter: 5,
      position: 'bottom-right',
      heading: header ? header : 'Fehler'
    },
    warning: {
      hideAfter: 5,
      position: 'bottom-right',
      heading: header ? header : 'Warnung'
    },
    success: {
      hideAfter: 5,
      position: 'bottom-right',
      heading: header ? header : 'Erfolg'
    },
  };

  if (errorCode) {
    switch (errorCode) {
      case '100':
        cogoToast.error('Ungültige Zugangsdaten.', toastOptions.error);
        break;
      case '102':
        cogoToast.error('Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.', toastOptions.error);
        break;
      case '103':
        cogoToast.error('Dieser Zugang ist nicht aktiv. Bitte kontaktieren Sie den Administrator.', toastOptions.error);
        break;
      case 105:
        cogoToast.error('Zeitüberschreitung. Passwort zurücksetzen bitte erneut anfordern.', toastOptions.error);
        break;
      case 107:
        cogoToast.error('E-Mail Adresse ungültig.', toastOptions.error);
        break;
      default:
        cogoToast.error('Anmeldung fehlgeschlagen. Kontrollieren Sie Ihr Passwort, probieren Sie einen moderneren Browser oder versuchen Sie es später erneut.', toastOptions.error)
    }
  }

  if (message) {
    switch (type) {
      case 'success':
        cogoToast.success(message, toastOptions.success);
        break;
      case 'error':
        cogoToast.error(message, toastOptions.error);
        break;
      default:
        return;
    }
  }

};

export default toastHandler;
