import React, { useContext, useEffect, useState } from 'react'
import { navigate }                               from 'gatsby'

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';

import { ApiContext }    from '../context/Api';
import { GlobalContext } from '../context/Global';

const PrivateRoute = ({ component: Component, location, redirect, ...rest }) => {
  const { isLoggedIn, authChecked } = useContext(ApiContext);
  const { pathBeforeRedirect, setPathBeforeRedirect } = useContext(GlobalContext);
  const [render, setRender] = useState(null);
  useEffect(() => {
    if (authChecked) {
      if (!isLoggedIn && location.pathname !== redirect) {
        setPathBeforeRedirect(location.pathname);
        navigate(redirect);
        setRender(null)
      } else {
        setRender(<Component {...rest} />)
      }
      if (isLoggedIn && pathBeforeRedirect) {
        navigate(pathBeforeRedirect);
        setPathBeforeRedirect(null);
      }
    } else {
      setRender(<SpinnerContainer><Spinner/></SpinnerContainer>)
    }
    //eslint-disable-next-line
  }, [authChecked, Component, location, isLoggedIn])

  return render;
};

export default PrivateRoute